import router from "@/router/index";
import { getCache } from "@/utils/auth";

// 登录白名单
const whiteList = ["/login"];

router.beforeEach((to, from, next) => {
  if (getCache()) {
    // to.meta.title && store.dispatch('settings/setTitle', to.meta.title);
    if (to.path === "/login") {
      next({ path: "/" });
    }
    // 菜单当前选中及页面持久
    // if (to.path !== "/login") {
    //   store.commit("permission/UPDATE_CURRENT_MODULE_NAME", to.meta.moduleName);
    //   store.commit("permission/UPDATE_CURRENT_PAGE", to.path);
    // }
    // 首页的时候组装左侧导航数据
    // if (to.path === "/home") {
    //   store.commit("permission/UPDATE_SUB_MENU", true);
    // }
     else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      const redirect = encodeURIComponent(to.fullPath); // 编码 URI，保证参数跳转回去后，可以继续带上
      next(`/login?redirect=${redirect}`); // 否则全部重定向到登录页
    }
  }
});