var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-header",style:({
    backgroundColor:
      _vm.sidebar.position === 'header' ? _vm.theme.layoutLeftBg : null,
  })},[_c('div',{staticClass:"dfa"},[(_vm.sidebar.position === 'left')?_c('div',{staticClass:"fold-btn",on:{"click":_vm.toggle}},[_c('icon',{attrs:{"name":_vm.sidebar.opened ? 'icon-unfold' : 'icon-fold',"scale":"1"}})],1):_vm._e(),(_vm.breadcrumbPosition === 'header')?_c('breadcrumb'):_vm._e(),(_vm.sidebar.position === 'header')?_c('layout-aside',{attrs:{"mode":"horizontal"}}):_vm._e()],1),_c('div',{staticClass:"right-menu"},[_c('el-dropdown',[_c('div',{staticClass:"avator-name-wrap"},[_c('img',{attrs:{"src":_vm.userInfo?.avatar}}),_c('div',{staticClass:"name",style:({
            color:
              _vm.sidebar.position === 'header' ? _vm.theme.layoutLeftText : null,
          })},[_vm._v(" "+_vm._s(_vm.userInfo?.username)+" ")])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.logout.apply(null, arguments)}}},[_c('span',[_vm._v("退出登录")])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }