/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attendance-replacement-approval': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M916.48 685.107c-9.728-21.913-23.757-41.574-40.96-57.805a178.719 178.719 0 00-122.88-48.742c-8.704 0-17.254.614-25.6 1.792a176.416 176.416 0 00-40.96 11.008c-66.048 26.42-112.64 90.982-112.64 166.4 0 55.654 25.344 105.37 65.126 138.24 31.028 25.6 70.759 40.96 114.074 40.96 73.114 0 135.987-43.776 163.84-106.547 9.882-22.221 15.36-46.797 15.36-72.653s-5.478-50.432-15.36-72.653zm-40.96 135.834C852.582 865.485 806.144 896 752.64 896c-8.755 0-17.306-.82-25.6-2.406-14.592-2.714-28.365-7.783-40.96-14.746-42.7-23.5-71.68-68.966-71.68-121.088s28.98-97.587 71.68-121.088c12.595-6.963 26.368-12.032 40.96-14.746a136.051 136.051 0 0125.6-2.406c53.504 0 99.942 30.515 122.88 75.06 9.83 18.943 15.36 40.447 15.36 63.18s-5.53 44.237-15.36 63.18z" _fill="#008CFF"/><path pid="1" d="M811.98 692.48l-87.5 87.5-34.662-34.662a20.521 20.521 0 00-28.98 0 20.521 20.521 0 000 28.98l47.053 47.052c4.506 4.506 10.65 6.35 16.538 5.786a20.275 20.275 0 0016.537-5.786l99.943-99.942a20.521 20.521 0 000-28.98c-7.936-7.935-20.941-7.935-28.928.052z" _fill="#008CFF"/><path pid="2" d="M496.64 896H148.48c-11.264 0-20.48-9.216-20.48-20.48V148.48c0-11.264 9.216-20.48 20.48-20.48h527.36v363.52c0 11.315 9.165 20.48 20.48 20.48s20.48-9.165 20.48-20.48V128h128c11.264 0 20.48 9.216 20.48 20.48v343.04c0 11.315 9.165 20.48 20.48 20.48s20.48-9.165 20.48-20.48V148.48c0-33.792-27.648-61.44-61.44-61.44H148.48c-33.792 0-61.44 27.648-61.44 61.44v727.04c0 33.792 27.648 61.44 61.44 61.44h348.16c11.315 0 20.48-9.165 20.48-20.48S507.955 896 496.64 896z" _fill="#666"/>'
  }
})
