<!-- 底部栏 -->
<template>
  <div class="layout-footer">2016-2022 © xiaoxi.work</div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.layout-footer {
  background-color: #f0f0f0;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #9ea7b4;
}
</style>
