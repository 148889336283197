<template>
  <div class="home">
    <h1>基座首页页面</h1>
    <icon></icon>
    <img alt="Vue logo" src="../assets/image/vue-logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/common-variables.less");

</style>