import { registerMicroApps, start, initGlobalState } from "qiankun";
import store from "./store";
import { getCache } from "@/utils/auth";
import { initialState } from "@/qiankun/globalState";

const loader = (loading) => {
  console.log("加载状态", loading);
  console.log(initialState,"initialState");
};



registerMicroApps(
  [
    {
      name: "system",
      entry:
        process.env.NODE_ENV === "production" ? "/system/" : "//localhost:1100", // //localhost:1100 默认vue启动的入口是1100端口
      activeRule: "/system", // 当路径是 /vue的时候启动
      container: "#Appmicro", // 应用挂载的位置
      loader,
      props: { a: 1, util: {},  },
    },
    {
      name: "oa",
      // entry: "https://oa.xiaoxi.work/", // 默认react启动的入口是1200端口
      entry:
        process.env.NODE_ENV === "production"
          ? "/oa/"
          : "//localhost:8081",
      activeRule: "/oa", // 当路径是 /react的时候启动
      container: "#Appmicro", // 应用挂载的位置
      loader,
      props: { a: 1, util: {}, globalState: initialState },
    },
    // {
    //   name: "reactApp",
    //   entry: "//localhost:1200", // 默认react启动的入口是1200端口
    //   activeRule: "/react", // 当路径是 /react的时候启动
    //   container: "#Appmicro", // 应用挂载的位置
    //   loader,
    //   props: { a: 1, util: {} },
    // },
  ],
  {
    beforeLoad() {
      console.log("before load");
    },
    beforeMount() {
      console.log("before mount");
    },
    afterMount() {
      console.log("after mount");
    },
    beforeUnmount() {
      console.log("before unmount");
    },
    afterUnmount() {
      console.log("after unmount");
    },
  }
);
start({
  sandbox: {
    // 实现了动态样式表
    // css-module,scoped 可以再打包的时候生成一个选择器的名字  增加属性 来进行隔离
    // BEM
    // CSS in js
    // shadowDOM 严格的隔离
    // strictStyleIsolation:true,
    //experimentalStyleIsolation:true // 缺点 就是子应用中的dom元素如果挂在到了外层，会导致样式不生效
    // loose: true,
  },
  // singular: false
});
