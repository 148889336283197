// import variables from "@/assets/css/common-variables.less";

export default {
  namespaced: true,
  state: {
    sidebar: {
      opened: true,
      themeType: "dark", // dark 暗黑模式  light  亮光模式
      position: "left", // left 左侧栏  header 头部
    },
    breadcrumbPosition: "header", // header 顶部栏  main 内容区
    themeType: "dark", // light 亮色 dark 暗色 realDark 暗黑 realLight 亮白
    theme: "", // 主题色
    logoVisible: true, // 是否显示logo
  },
  mutations: {
    set_sidebar(state, { key, value }) {
      state.sidebar[key] = value;
    },
    set_breadcrumb_position(state, value) {
      state.breadcrumbPosition = value;
    },
    set_logo_visible(state, value) {
      state.logoVisible = value;
    },
    set_theme_type(state, value) {
      state.themeType = value;
      if (value === "light") {
        state.sidebar.themeType = value;
        state.sidebar.position = "left";
      } else if (value === "dark") {
        state.sidebar.themeType = value;
        state.sidebar.position = "left";
      } else if (value === "realDark") {
        state.sidebar.themeType = "dark";
        state.sidebar.position = "header";
      } else {
        state.sidebar.themeType = "light";
        state.sidebar.position = "header";
      }
    },
  },
  actions: {
    setSidebar({ commit }, { key, value }) {
      commit("set_sidebar", { key, value });
    },
    toggleCollapse({ commit }, value) {
      commit("set_sidebar", { key: "opened", value });
    },
    setBreadcrumbPosition({ commit }, value) {
      commit("set_breadcrumb_position", value);
    },
  },
  getters: {
    sidebar: (state) => state.sidebar,
    breadcrumbPosition: (state) => state.breadcrumbPosition,
    themeType: (state) => state.themeType,
    logoVisible: (state) => state.logoVisible,
  },
};
