import Vue from "vue";

// import 'normalize.css'
// import '@/assets/styl/global.styl'
import "./element";
import "./svg";
import "./router";
import "./permission";
import { qiankunActions } from "@/qiankun/index";

Vue.prototype.$actions = qiankunActions;