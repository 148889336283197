/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attendance-staff-attendance': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M500.907 736.427c.213 0 .426.213.426.213 1.067.64 2.347 1.067 3.414 1.493.64.214 1.066.427 1.706.64 1.707.427 3.2.64 4.907.64h.853c1.707 0 3.627-.213 5.334-.853.64-.213 1.066-.427 1.706-.64 1.28-.427 2.56-1.067 3.627-1.707.213-.213.427-.213.64-.213C614.827 676.693 768 554.027 768 398.72c0-137.387-114.773-249.387-256-249.387s-256 112-256 249.387c0 118.4 91.52 244.48 244.907 337.707zM512 192c117.547 0 213.333 92.8 213.333 206.72 0 129.28-128.853 237.013-213.546 293.973-133.547-84.48-213.12-193.493-213.12-293.973C298.667 284.8 394.453 192 512 192z"/><path pid="1" d="M512 469.333h106.667c11.733 0 21.333-9.6 21.333-21.333s-9.6-21.333-21.333-21.333h-85.334v-128c0-11.734-9.6-21.334-21.333-21.334s-21.333 9.6-21.333 21.334V448c0 11.733 9.6 21.333 21.333 21.333zm162.133 234.24a21.248 21.248 0 00-25.386 16.214c-2.56 11.52 4.693 22.826 16.213 25.386 64.64 14.294 81.067 32.427 81.707 36.054C744.32 796.373 661.333 832 512 832s-232.32-35.627-234.667-50.773c.64-3.627 17.067-21.76 81.707-36.054 11.52-2.56 18.773-13.866 16.213-25.386s-14.08-18.774-25.386-16.214c-75.307 16.64-115.2 43.52-115.2 77.654 0 64.213 143.786 93.44 277.333 93.44s277.333-29.227 277.333-93.44c0-34.347-39.893-61.227-115.2-77.654z"/>'
  }
})
