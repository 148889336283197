/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'system-user': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M511.914 941.605c-255.613 0-385.312-57.452-385.312-170.81 0-80.846 133.655-133.999 266.622-151.888v-16.858c-79.986-55.904-118.861-153.436-118.861-297.24 0-139.331 87.21-222.586 233.423-222.586h7.912c146.212 0 233.423 83.255 233.423 222.586 0 54.184 0 214.674-117.83 297.412l-.343 16.686c132.967 18.061 266.106 71.042 266.106 151.716.172 113.53-129.527 170.982-385.14 170.982zm-3.956-800.037c-79.47 0-174.25 28.382-174.25 163.241 0 129.699 34.23 213.47 104.584 255.785 8.945 5.332 14.277 14.965 14.277 25.286v59.345c0 15.31-11.525 28.038-26.662 29.414-144.32 14.45-239.96 67.43-239.96 95.984 0 92.2 177.347 111.637 325.967 111.637 148.792 0 325.967-19.265 325.967-111.637 0-28.726-95.64-81.534-239.96-95.984a29.517 29.517 0 01-26.662-30.102l1.376-59.345c.172-10.149 5.676-19.438 14.277-24.77 70.526-42.488 103.209-123.678 103.209-255.785 0-135.031-94.78-163.241-174.25-163.241l-7.913.172z" _fill="#575B66"/>'
  }
})
