<!-- 配置面板 -->
<template>
  <div class="config-panel">
    <panel>
      <el-col>
        <h3 class="config-item-title">整体风格设置</h3>
        <div class="theme-list">
          <div
            class="theme-item"
            v-for="item in themeList"
            :key="item.key"
            :title="item.title"
            :class="[item.key]"
            @click="handleChange('themeType', item.key)"
          >
          <icon
            name="check"
            class="select-icon"
            scale="0.6"
            :style="{ display: themeType === item.key ? 'block' : 'none' }"
          ></icon>
          </div>
        </div>
      </el-col>
      <el-col>
        <h3 class="config-item-title">面包屑位置</h3>
        <el-radio-group
          v-model="breadcrumb"
          @change="(val) => handleChange('breadcrumbPosition', val)"
        >
          <el-radio
            label="header"
            :disabled="themeType === 'realDark' || themeType === 'realLight'"
            >顶部栏</el-radio
          >
          <el-radio label="main">内容区</el-radio>
        </el-radio-group>
      </el-col>
      <el-col>
        <h3 class="config-item-title">侧边栏 Logo</h3>
        <el-switch
          v-model="logo_visible"
          active-text="展示"
          inactive-text="隐藏"
          @change="(val) => handleChange('logoVisible', val)"
        >
        </el-switch>
      </el-col>
    </panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Panel from "./template/Panel.vue";
import config from "@/config/config";
export default {
  name: "ConfigPanel",
  components: { Panel },
  computed: {
    ...mapGetters("app", [
      "sidebar",
      "themeType",
      "breadcrumbPosition",
      "logoVisible",
    ]),
  },
  data() {
    return {
      themeList: [
        {
          key: "dark",
          title: "暗色风格",
        },
        {
          key: "light",
          title: "亮色风格",
        },
        {
          key: "realDark",
          title: "暗黑风格",
        },
        {
          key: "realLight",
          title: "亮白风格",
        },
      ],
      breadcrumb: this.$store.getters["app/breadcrumbPosition"],
      logo_visible: this.$store.getters["app/logoVisible"],
    };
  },
  methods: {
    handleChange(type, value) {
      if (type === "themeType") {
        this.$store.commit(`app/set_theme_type`, value);
        if (value === "realDark" || value === "realLight") {
          this.breadcrumb = "main";
          this.$store.commit(`app/set_breadcrumb_position`, "main");
        }
      } else if (type === "breadcrumbPosition") {
        this.$store.commit(`app/set_breadcrumb_position`, value);
      } else if (type === "logoVisible") {
        this.$store.commit(`app/set_logo_visible`, value);
      }
    },
  },
  created() {},
  mounted() {
    console.log(this.breadcrumbPosition);
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/common-variables.less");
.config-item-title {
  font-weight: 400;
  color: @page-color;
  text-align: left;
  padding-bottom: 10px;
}
.el-col {
  margin-bottom: 15px;
}
.theme-list {
  display: flex;
  align-items: center;
  .theme-item {
    position: relative;
    width: 44px;
    height: 36px;
    margin-right: 16px;
    overflow: hidden;
    background-color: #f0f2f5;
    border-radius: 4px;
    box-shadow: 0 1px 2.5px rgba(0, 0, 0, 0.18);
    cursor: pointer;
    .select-icon {
      position: absolute;
      right: 6px;
      bottom: 4px;
      color: @page-color;
      font-weight: 700;
      font-size: 14px;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 33%;
      height: 100%;
      background-color: @layout-header-bg;
      content: "";
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 25%;
      background-color: @layout-header-bg;
      content: "";
    }
    &.light {
      &::before {
        background-color: @layout-header-bg;
        content: "";
      }
      &::after {
        background-color: @layout-header-bg;
      }
    }
    &.dark {
      &::before {
        z-index: 1;
        background-color: @layout-left-bg;
        content: "";
      }
      &::after {
        background-color: @layout-header-bg;
      }
    }
    &.realDark {
      &::before {
        // z-index: 1;
        // background-color: fade(@layout-left-bg, 65%);
        // content: "";
        background-color: @layout-main-bg;
      }
      &::after {
        background-color: @layout-left-bg;
      }
    }
    &.realLight {
      &::before {
        // z-index: 1;
        // background-color: fade(@layout-left-bg, 65%);
        // content: "";
        background-color: @layout-main-bg;
      }
      &::after {
        background-color: @layout-header-bg;
      }
    }
  }
}
</style>
