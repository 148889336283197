import request from "@/utils/request";

// 获取oa系统的权限菜单
export const commonMenu = (params) => {
  return request({
    url: "/common/menu",
    method: "get",
    data: { params },
  });
};
