/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apply-posttransfer': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M434 135c-93.3 0-169 75.7-169 169 0 85.3 63.2 155.6 145.3 167.2 7.9-.5 39.4-.5 47.3 0C539.8 459.6 603 389.3 603 304c0-93.3-75.7-169-169-169zm101 350.8c23.9 7.6 46.4 17.8 67.3 30.4L575.2 550c-41.7-24.1-90.1-38-141.2-38-146.5 0-277.1 112.6-273 260 2.9 105.6 126.5 117 273 117 79.6 0 153.1-2.7 203.8-22.5l55.9 22.4c-3.3 2.6-6.4 5.1-10.1 7.7-43.2 30-124.8 31.4-124.8 31.4H293.6s-70.1-3.4-125.1-47.4C138.1 856.4 122 822 122 771c0-137.8 88.3-246.4 211-285.3-63.8-35.5-107-103.5-107-181.7 0-114.9 93.1-208 208-208s208 93.1 208 208c0 78.2-43.2 146.2-107 181.8zM720 590v52.2l104-.2v26H642l78-78zm26 130H642v-26h182l-78 78v-52zm-13-169c-71.8 0-130 58.2-130 130s58.2 130 130 130 130-58.2 130-130-58.2-130-130-130zm0 299c-93.4 0-169-75.7-169-169 0-93.4 75.6-169 169-169 93.3 0 169 75.6 169 169 0 93.3-75.7 169-169 169z"/>'
  }
})
