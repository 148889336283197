<!-- 公共视图 -->
<template>
   <div class="route-view">
    <router-view></router-view>
   </div>
</template>

<script>
export default {
    name:"",
    data() {
        return {

        }
    },
    methods: {

    },
    created() {

    },
    mounted() {

    }
}
</script>
<style lang="scss" scoped>

</style>