/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M512 13.033A510.138 510.138 0 0090.53 809.658a35.142 35.142 0 0028.86 15.127 33.978 33.978 0 0020.246-6.05 34.676 34.676 0 009.31-48.408A438.924 438.924 0 0173.774 541.79h56.785a37.702 37.702 0 000-75.17H76.335A437.993 437.993 0 01162.909 256l32.582 32.582a37.702 37.702 0 0053.294-53.062l-34.676-35.142A439.156 439.156 0 01477.324 84.015a34.444 34.444 0 00-2.095 11.869v59.345a37.702 37.702 0 0075.171 0V96.35a40.96 40.96 0 00-2.095-11.869 437.993 437.993 0 01261.586 114.735l-36.306 36.305a37.702 37.702 0 0053.295 53.062l34.21-33.978a436.596 436.596 0 0188.205 212.014H893.44a37.702 37.702 0 000 75.171h58.88a437.295 437.295 0 01-72.61 224.815 34.91 34.91 0 0058.18 38.632A510.37 510.37 0 00512 13.033z" _fill="#fff"/><path pid="1" d="M608.815 377.25L498.036 488.728a51.433 51.433 0 00-8.61 0 62.37 62.37 0 1062.37 62.138 46.545 46.545 0 000-7.912l111.011-111.011a37.47 37.47 0 00-53.062-53.062z" _fill="#fff"/>'
  }
})
