/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nested': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M810.667 640l-256 256-60.587-60.587 153.173-152.746H170.667v-512H256v426.666h391.253L494.08 444.587 554.667 384l256 256z"/>'
  }
})
