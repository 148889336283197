import store from "@/store/index";
import router from "@/router/index";
import { initGlobalState } from "qiankun";

// 定义全局下发的数据
export const initialState = {
  // 当前登录用户
  userInfo: null,
  // 全局配置
  globalConfig: null,
  // 路由数据
  routers: null,
  // 实现子应用直接跳转子应用
  currentRoute: {
    // 当前页面
    currentPage: "",
    // 当前模块
    currentModuleName: "",
  },
};

// 初始化全局下发的数据
export const qiankunActions = initGlobalState(initialState);

qiankunActions.onGlobalStateChange((state, oldVal) => {
  console.log("基座接受子应用 change事件", state);
  for (const key in state) {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      const item = state[key];
      initialState[key] = item;
      if (key === "userInfo") {
        store.commit("user/setUserInfo", item);
        continue;
      }
    }
  }
  console.log(initialState);
});
