/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-unfold': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M163.28000000000003 192.37h700.08q32.17 0 32.17 32.17t-32.17 32.17H163.28q-32.17 0-32.17-32.17t32.17-32.17zM163.28000000000003 766.83h700.08q32.17 0 32.17 32.17t-32.17 32.17H163.28q-32.17 0-32.17-32.17t32.17-32.17zM481.5 385.09h381.87q32.17 0 32.17 32.17t-32.17 32.17H481.5q-32.17 0-32.17-32.17t32.17-32.17zM481.5 578.11h381.87q32.17 0 32.17 32.17t-32.17 32.17H481.5q-32.17 0-32.17-32.17t32.17-32.17zM129.91 512.16L321.14 639.7V384.62L129.91 512.16z" _fill="#999"/>'
  }
})
