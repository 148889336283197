/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apply-changesalary': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M538 707h-39V603H395v-39h104v-52H395v-39h91l-91-156h39c46.3 84.6 86.4 145.3 86.4 145.3h1L603 317h39l-91 156h91v39H538v52h104v39H538v104zm-26-546c-193.9 0-351 157.2-351 351s157.2 351 351 351 351-157.2 351-351c0-193.9-157.1-351-351-351zm0 741c-215.4 0-390-174.6-390-390s174.6-390 390-390 390 174.6 390 390-174.6 390-390 390z"/>'
  }
})
