/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'base_info': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M234.656 213.344H256A106.656 106.656 0 00362.656 320h298.688A106.656 106.656 0 00768 213.344h21.344a85.344 85.344 0 0185.312 85.312v512A85.344 85.344 0 01789.344 896H234.656a85.344 85.344 0 01-85.312-85.344v-512a85.344 85.344 0 0185.312-85.312zm85.344 192A21.344 21.344 0 10320 448h362.656a21.344 21.344 0 100-42.656H320zm0 149.312a21.344 21.344 0 000 42.688h362.656a21.344 21.344 0 000-42.688H320zM320 704a21.344 21.344 0 100 42.656h234.656a21.344 21.344 0 100-42.656H320zm42.656-554.656h298.688a64 64 0 110 128H362.656a64 64 0 010-128z"/>'
  }
})
