/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'approval-posttransfer': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M281.259 309.248c0 127.317 103.082 230.741 230.741 230.741s230.741-103.082 230.741-230.741v-.341c0-127.318-103.424-230.742-230.741-230.742-127.317.342-230.741 103.424-230.741 231.083z" _fill="#06CC76" opacity=".6"/><path pid="1" d="M462.165 388.096l-261.12 375.467c-26.624 38.57 2.048 89.77 49.835 89.77h522.24c47.787 0 76.459-51.2 49.835-89.429l-261.12-375.808c-23.552-34.133-76.118-34.133-99.67 0z" _fill="#06CC76" opacity=".6"/><path pid="2" d="M556.032 393.899h-88.064c-19.115 0-35.157-15.702-35.157-35.158 0-19.114 15.701-35.157 35.157-35.157h88.064c19.456 0 35.157 15.701 35.157 35.157S575.488 393.9 556.032 393.9z" _fill="#FFF"/><path pid="3" d="M587.0930000000001 730.112a123.221 123.221 0 10246.443 0 123.221 123.221 0 10-246.443 0z" _fill="#06CC76" opacity=".6"/><path pid="4" d="M775.168 723.285l-56.32-56.32c-4.779-4.778-12.63-4.778-17.408 0l-56.32 56.32c-6.144 6.144-1.707 16.043 6.827 16.043h24.234v51.2c0 6.485 5.12 11.605 11.606 11.605h45.397c6.485 0 11.605-5.12 11.605-11.605v-51.2h24.235c8.192 0 12.288-10.24 6.144-16.043z" _fill="#FFF"/>'
  }
})
