import { login } from "@/api/user";
import { commonMenu } from "@/api/common";
import { setCache, removeCache } from "@/utils/auth";

export default {
  namespaced: true,
  state: {
    userInfo: null,
    menuRoutes: null,
    tokens: {},
  },
  mutations: {
    setUserInfo(state, value) {
      let userInfo = { ...state["userInfo"], ...value };
      setCache(userInfo, `user_info`);
      state["userInfo"] = userInfo;
    },
    setMenuRoutes(state, value) {
      state["menuRoutes"] = value;
    },
    setTokens(state, { key, token }) {
      setCache(token);
      state.tokens[key] = token;
    },
  },
  actions: {
    /**
     * 登录系统
     */
    customLogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then((res) => {
            if (res.status === 200) {
              commit("setTokens", { key: "oa-token", token: res.data.token });
              let userInfo = {
                isInduction: res.data.isInduction,
                oa_token: res.data.token,
              };
              commit("setUserInfo", userInfo);
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 获取用户所拥有的菜单
     */
    getMenuRoutes({ commit }, params) {
      return new Promise((resolve, reject) => {
        commonMenu(params)
          .then((res) => {
            if (res.status === 200) {
              commit("setMenuRoutes", res.data);
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    logout({ commit }) {
      removeCache();
      removeCache("user_info");
      location.href = "/login";
    },
  },
  getters: {
    userInfo: (state) => state.userInfo,
    menuRoutes: (state) => state.menuRoutes,
  },
};
