<!-- 面板 -->
<template>
  <div class="panel-page" :class="isOpen ? 'panel-open' : ''">
    <div class="panel-bg"></div>
    <div class="panel">
      <slot name="btn">
        <div class="panel-btn" @click="toggle">
          <i :class="isOpen ? 'el-icon-close' : 'el-icon-setting'"></i>
        </div>
      </slot>
      <div class="panel-container">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Panel",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.panel-page {
  .panel-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 15px);
    height: 100%;
    opacity: 1;
    z-index: -1;
  }

  .panel {
    width: calc(100% - 15px);
    max-width: 260px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    transform: translate(100%);
    background: #fff;
    z-index: 40000;
    .panel-btn {
      top: 250px;
      width: 48px;
      height: 48px;
      position: absolute;
      left: -48px;
      text-align: center;
      font-size: 24px;
      border-radius: 6px 0 0 6px !important;
      z-index: 0;
      pointer-events: auto;
      background: rgb(24, 144, 255);
      cursor: pointer;
      color: #fff;
      line-height: 48px;
      i {
        font-size: 24px;
        line-height: 48px;
      }
    }
  }

  .panel-container {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }
}
// 打开配置中心
.panel-open {
  .panel-bg {
    background: rgba(0, 0, 0, 0.2);
    z-index: 20000;
  }
  .panel {
    transform: translate(0);
  }
}
</style>
