<!-- 内容区 -->
<template>
  <div class="layout-main">
    <!-- 内容区 -->
    <breadcrumb v-if="breadcrumbPosition === 'main'" />
    <router-view></router-view>
    <div id="Appmicro"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "LayoutMain",
  components: { Breadcrumb },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("app", [ "breadcrumbPosition"]),
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/common-variables.less");
.layout-main {
  background: @layout-main-bg;
  min-height: calc(100vh - @layout-header-height);
}
#Appmicro {
  // min-height: calc(100vh - @layout-header-height - 24px);
  overflow: hidden;
}
</style>
