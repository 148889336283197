/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attendance': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M761.7 438.2c-9.6 0-18.5 1.6-27.2 4.6-.9-.4-1.9-.8-2.9-1.1-16.4-26.2-44.2-42.3-75.5-42.3-10.6 0-21 1.8-30.9 5.2-16.2-27.2-44.6-44.1-76.8-44.1-5.8 0-11.4.6-17 1.6v-49.4c0-51.6-39.5-91.9-90-91.9-51 0-92.5 41.3-92.5 91.9v243l-14.2-14.3C318.2 525 295.2 516 270 516c-25.4 0-50.1 9.5-66.1 25.4-34.7 34.7-46.2 94.8-5.5 135.6L406 883.3c4.8 4.7 10.3 9 16.2 12.7 38.9 31.6 85.4 52.4 183.5 52.4 228.1 0 247.2-133.7 247.2-270.7V530.2c-.1-51.6-39.3-92-91.2-92zM605.8 912.3c-88.5 0-127.4-17.3-161.6-45.1l-2.1-1.5c-4-2.5-7.6-5.1-10.6-8.1L223.9 651.3c-27.1-27.2-13.1-65.6 5.6-84.2 9.1-9.1 24.7-14.8 40.4-14.8 15.6 0 29.6 5.2 39.2 14.8l76.1 75.8V312.5c0-30.7 25.2-55.7 56.2-55.7 30.1 0 53.8 24.4 53.8 55.7v105.2l.4-.2v108c0 10 8.1 18.1 18.1 18.1 10 0 18.1-8.1 18.1-18.1v-126c5.4-1.7 10.8-2.8 16.5-2.8 23.4 0 43 14.5 50.9 37.8l2.3 6.7V560c0 10 8.1 18.1 18.1 18.1 10 0 18.1-8.1 18.1-18.1V438.8c5.9-2 11.9-3.2 18.2-3.2 23.4 0 42.8 14.5 50.9 37.8l1.2 3.6v118.6c0 10 8.1 18.1 18.1 18.1 10 0 18.1-8.1 18.1-18.1V478c5.8-2.1 11.9-3.6 19.2-3.6 30.2 0 53 24 53 55.8l.3 147.6c0 132.8-15.5 234.5-210.9 234.5z" _fill="#3B71B7"/><path pid="1" d="M292.6 483.2c3.2 2.5 7 3.6 10.8 3.6 5.6 0 11-2.6 14.5-7.3 6-8 4.3-19.4-3.7-25.3-48.5-36.2-76.3-91.5-76.3-151.9 0-104.3 84.9-189.2 189.2-189.2 104.3 0 189.2 84.9 189.2 189.2 0 12.7-1.2 25.3-3.8 37.7-2 9.8 4.4 19.4 14.2 21.4 9.8 1.9 19.4-4.4 21.4-14.2 3-14.7 4.5-29.8 4.5-44.9C652.6 178.2 551.4 77 427.1 77S201.6 178.2 201.6 302.5c0 70.6 33.9 138.3 91 180.7z" _fill="#499DD7"/>'
  }
})
