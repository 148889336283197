/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M426.667 853.333v-256h170.666v256h213.334V512h128L512 128 85.333 512h128v341.333h213.334z"/>'
  }
})
