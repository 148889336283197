/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apply-induction': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M119.132 820.098V263.362c0-7.316 5.962-13.264 13.278-13.264h276.737c6.885 47.352 47.744 83.85 96.974 83.85s90.1-36.498 96.987-83.85h285.228c7.303 0 13.264 5.948 13.264 13.264v327.095h56.008V263.362c0-38.191-31.068-69.272-69.272-69.272h-284.2v-70.176c0-15.466-12.525-28.005-28.004-28.005h-140.01c-15.464 0-28.003 12.539-28.003 28.005v70.175h-275.71c-38.204 0-69.285 31.08-69.285 69.272v556.736c0 38.191 31.08 69.272 69.286 69.272h450.667v-56.008H132.41c-7.316 0-13.278-5.947-13.278-13.263zm344.995-668.18h84v84.013c0 23.163-18.843 42.007-42.007 42.007s-41.993-18.843-41.993-42.007v-84.013z" _fill="#3E3A39"/><path pid="1" d="M174.69 662.628v52.043c0 15.465 12.539 28.004 28.003 28.004h343.82c15.478 0 28.003-12.54 28.003-28.004v-52.043a28.001 28.001 0 00-15.479-25.05l-103.21-51.579 26.691-38.834a28.06 28.06 0 004.923-15.862v-81.975c0-60.863-48.639-108.544-110.745-108.544-62.094 0-110.746 47.681-110.746 108.544v81.975a27.988 27.988 0 005.182 16.23l27.197 38.247-107.737 51.592a27.997 27.997 0 00-15.903 25.256zm56.009 17.64l121.835-58.348a27.985 27.985 0 0015.014-18.241 28.045 28.045 0 00-4.293-23.246L321.96 522.36v-73.033c0-29.454 24.038-52.536 54.737-52.536s54.737 23.082 54.737 52.536v73.28l-39.996 58.196a28.04 28.04 0 00-4.035 22.862 27.988 27.988 0 0014.59 18.05L518.51 679.94v6.728H230.699v-6.4zm717.474-12.526c-11.568-10.173-29.317-9.106-39.518 2.544l-146.558 166.63-101.05-77.258c-12.28-9.38-29.837-7.042-39.272 5.238-9.38 12.292-7.028 29.863 5.25 39.257l121.836 93.134a27.912 27.912 0 0017.01 5.756 27.936 27.936 0 0021.03-9.517l163.815-186.254c10.201-11.62 9.08-29.316-2.543-39.53zm-211.018-75.535c15.48 0 28.005-12.54 28.005-28.004s-12.525-28.004-28.005-28.004H623.361c-15.48 0-28.004 12.539-28.004 28.004s12.525 28.004 28.004 28.004h113.794zM623.361 417.481c-15.48 0-28.004 12.54-28.004 28.004s12.525 28.005 28.004 28.005H801.56c15.479 0 28.004-12.54 28.004-28.005s-12.525-28.004-28.004-28.004h-178.2z" _fill="#3E3A39"/>'
  }
})
