/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dictionary': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M871.744 0a64 64 0 0164 64v768a64 64 0 01-64 64h-704v32l.512 5.76A32 32 0 00199.744 960h704l5.76.512a32 32 0 01-5.76 63.488h-704l-9.216-.448A96 96 0 01103.744 928V64a64 64 0 0164-64h704zm-400.64 522.112c-29.568 23.424-67.584 42.624-113.664 57.216l17.28 37.248c47.616-17.664 87.936-39.552 121.344-65.664l-24.96-28.8zm112.128 0l-20.352 30.336c39.552 15.36 78.336 36.48 115.968 63.36l21.504-33.792a599.232 599.232 0 00-117.12-59.904zM505.664 256h-38.016v46.848h-82.176v178.944h-34.176v38.4h352.896v-38.4h-32.64V302.848H589.76V256h-38.016v46.848h-46.08V256zm-38.016 170.88v54.912h-43.776V426.88h43.776zm84.096 0v54.912h-46.08V426.88h46.08zm81.408 0v54.912H589.76V426.88h43.392zm-165.504-87.168V390.4h-43.776v-50.688h43.776zm84.096 0V390.4h-46.08v-50.688h46.08zm81.408 0V390.4H589.76v-50.688h43.392z" _fill="#fff"/>'
  }
})
