/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'approval-changesalary': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M810.667 515.541C796.8 513.195 782.528 512 768 512c-141.376 0-256 114.624-256 256 0 89.045 45.461 167.467 114.432 213.333H128A85.333 85.333 0 0142.667 896V128A85.333 85.333 0 01128 42.667h448V192a85.333 85.333 0 0085.333 85.333h149.334v238.208zM202.667 192a32 32 0 000 64h234.666a32 32 0 000-64H202.667zm0 170.667a32 32 0 000 64h448a32 32 0 000-64h-448zM460.843 640L321.408 521.344A30.25 30.25 0 00299.733 512c-8.106 0-15.893 3.285-21.653 9.173L140.672 638.507a32.597 32.597 0 002.603 42.88 30.208 30.208 0 0041.6 1.28l84.138-62.059v243.52c0 17.6 13.846 31.872 30.912 31.872s30.891-14.272 30.891-31.872v-243.52l86.87 63.53c12.245 8.79 28.8 7.297 39.402-3.54A32.64 32.64 0 00460.843 640zM768 981.333c-117.824 0-213.333-95.509-213.333-213.333S650.176 554.667 768 554.667 981.333 650.176 981.333 768 885.824 981.333 768 981.333zm105.237-185.536H788.46v-37.653h84.778c7.894.576 15.424-2.837 19.627-8.875a16.96 16.96 0 000-19.669c-4.075-6.037-11.733-9.45-19.627-8.875h-54.805l54.677-48.576a17.557 17.557 0 006.294-13.29 17.963 17.963 0 00-6.294-13.312 23.147 23.147 0 00-29.866 0l-77.867 68.906-77.739-68.906a23.04 23.04 0 00-29.994 0 17.557 17.557 0 00-6.294 13.312c0 4.992 2.347 9.77 6.294 13.29l54.912 48.576h-51.328c-11.222.576-19.99 8.747-19.99 18.774 0 10.005 8.747 18.197 19.99 18.773h84.778v37.525H661.1c-11.606 0-21.099 8.406-21.099 18.774 0 10.346 9.387 18.773 21.12 18.773h84.885v43.776c0 10.453 9.494 18.88 21.227 18.88s21.227-8.427 21.227-18.88v-43.797h84.778c11.606 0 21.099-8.406 21.099-18.774 0-10.346-9.387-18.773-21.12-18.773z" _fill="#FAAD14"/><path pid="1" d="M576 42.667l234.667 234.666H661.333A85.333 85.333 0 01576 192V42.667z" _fill="#FFD37C"/>'
  }
})
