/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'system-permission': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M101.888 985.088l-20.48-130.56 270.336-371.712a278.22 278.22 0 0116.896-304.128 279.347 279.347 0 01183.808-112.64c74.24-11.776 148.48 6.144 209.408 50.176s100.864 109.568 112.64 183.808c11.776 74.24-6.144 148.48-50.176 209.408a279.347 279.347 0 01-183.808 112.64c-43.52 6.656-88.064 3.584-130.048-9.728l-15.36 20.992 1.024 94.208-98.816 39.936L245.76 976.384l-143.872 8.704zm33.28-117.248l10.24 63.488 73.728-4.608 146.432-201.216 79.872-32.256-1.024-76.288 48.128-66.56 18.432 7.68c38.4 15.36 80.384 19.968 121.856 13.824 60.928-9.728 114.176-42.496 150.528-92.16 36.352-49.664 50.688-110.592 40.96-171.52-9.728-60.928-42.496-114.176-92.16-150.528s-110.592-50.688-171.52-40.96c-60.928 9.216-114.688 41.984-150.528 92.16-36.352 49.664-51.2 110.592-41.472 171.008 5.12 32.256 16.896 62.976 34.816 90.112l9.728 14.848L135.168 867.84z" _fill="#979797"/><path pid="1" d="M685.568 372.736c-5.12 0-10.752-1.536-14.848-5.12l-136.704-99.328a25.805 25.805 0 01-9.728-28.16c12.288-42.496 48.64-74.24 92.672-81.408 30.72-4.608 60.928 2.56 85.504 20.48 25.088 17.92 41.472 45.056 46.08 75.264 6.656 43.52-12.288 88.064-48.64 113.152a21.504 21.504 0 01-14.336 5.12zm-104.96-133.632l103.424 75.264a64.051 64.051 0 00-11.264-93.184c-13.824-10.24-30.72-13.824-47.616-11.264-18.432 3.072-34.816 13.824-44.544 29.184zM851.968 972.8H625.152c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h226.816c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6zM763.392 870.4H629.248c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h134.144c14.336 0 25.6 11.264 25.6 25.6s-11.776 25.6-25.6 25.6z" _fill="#979797"/>'
  }
})
