/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apply-regularization': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M682.667 938.667h-640C17.067 938.667 0 921.6 0 896v-85.333C0 691.2 93.867 597.333 213.333 597.333H512c119.467 0 213.333 93.867 213.333 213.334V896c0 25.6-17.066 42.667-42.666 42.667zM85.333 853.333H640v-42.666c0-72.534-55.467-128-128-128H213.333c-72.533 0-128 55.466-128 128v42.666zM362.667 512c-119.467 0-213.334-93.867-213.334-213.333S243.2 85.333 362.667 85.333 576 179.2 576 298.667 482.133 512 362.667 512zm0-341.333c-72.534 0-128 55.466-128 128s55.466 128 128 128 128-55.467 128-128-55.467-128-128-128zm448 426.666c-12.8 0-21.334-4.266-29.867-12.8L695.467 499.2c-17.067-17.067-17.067-42.667 0-59.733s42.666-17.067 59.733 0l55.467 55.466 140.8-140.8c17.066-17.066 42.666-17.066 59.733 0s17.067 42.667 0 59.734L840.533 584.533c-8.533 8.534-17.066 12.8-29.866 12.8z" _fill="#333"/>'
  }
})
