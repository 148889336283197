/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'system-role': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M808.198 557.056c27.008-20.416 49.472-47.616 65.664-79.744a267.52 267.52 0 0028.8-121.6c0-133.184-95.36-242.112-213.12-242.112-16.64 0-30.08 14.592-30.08 32.64 0 17.92 13.44 32.512 30.08 32.512 84.032 0 152.384 79.296 152.384 176.512 0 97.28-68.352 176.512-152.32 176.512-16.64 0-30.144 14.592-30.144 32.64 0 17.92 13.44 32.512 30.08 32.512 151.04 0 274.176 142.016 274.176 316.096 0 17.984 13.504 32.576 30.144 32.576s30.08-14.592 30.08-32.64a408.256 408.256 0 00-63.36-223.616c-38.656-60.8-91.2-105.984-152.32-132.288zm-262.912-15.488A257.728 257.728 0 00669.766 321.6c0-142.016-115.968-257.6-258.432-257.6-142.528 0-258.496 115.584-258.496 257.6 0 48.384 13.44 95.424 39.104 136.192 21.568 34.496 51.2 63.616 86.336 84.672a395.328 395.328 0 00-104.32 53.312 402.112 402.112 0 00-91.712 89.152A401.536 401.536 0 00.006 929.984a30.08 30.08 0 0060.224 0c0-93.184 36.48-180.544 102.528-246.848a351.552 351.552 0 01247.68-102.592c3.136.448-1.856.448.896 0 89.856 1.792 182.464 37.632 246.72 101.696a346.432 346.432 0 01102.528 246.848 30.08 30.08 0 0060.224 0 402.56 402.56 0 00-82.24-245.056 364.736 364.736 0 00-90.368-89.6 421.952 421.952 0 00-102.912-52.864zM411.334 124.48a197.76 197.76 0 01197.76 197.12 197.76 197.76 0 01-197.76 197.12 197.76 197.76 0 01-197.76-197.12 197.44 197.44 0 01197.76-197.12z"/>'
  }
})
