/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M371.59 862.528a47.232 47.232 0 01-30.72-9.92L13.19 531.136a42.368 42.368 0 010-61.376 42.432 42.432 0 0161.44 0l295.488 292.224 579.264-587.392a42.432 42.432 0 0161.44 0 42.368 42.368 0 010 61.44L402.31 849.664a40.576 40.576 0 01-30.72 12.864z" _fill="#333"/>'
  }
})
