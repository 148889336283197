/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'system-department': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M890.97 479.672H545.11V271.386H684.79V64.5h-341.76v206.886h137.31v208.286H133.397V755.515H64.285V959.5H270.16V755.515h-67.663V548.63h277.84v206.886h-70.552V959.5h205.877V755.515H545.11V548.63h277.114v206.886h-68.361V959.5h205.85V755.515H890.97z"/>'
  }
})
