export function getCache(TokenKey = "access_token") {
  return JSON.parse(localStorage.getItem(TokenKey));
}

export function setCache(token, TokenKey = "access_token") {
  return localStorage.setItem(TokenKey, JSON.stringify(token));
}

export function removeCache(TokenKey = "access_token") {
  return localStorage.removeItem(TokenKey);
}

export function clearCache() {
  return localStorage.clear();
}
