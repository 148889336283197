/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'oa-logo': {
    width: 31.125,
    height: 24,
    viewBox: '0 0 1328 1024',
    data: '<path pid="0" d="M983.485 861.582h-252.67a40.71 40.71 0 010-81.42h252.58a40.71 40.71 0 11.09 81.42zM372.298 785.5H355.29a134.342 134.342 0 01-134.16-134.16V374.422a134.342 134.342 0 01134.16-134.16h17.008a134.342 134.342 0 01134.16 134.16V651.34A134.342 134.342 0 01372.299 785.5zM355.29 321.682a52.832 52.832 0 00-52.741 52.741V651.34a52.832 52.832 0 0052.741 52.742h17.008a52.832 52.832 0 0052.741-52.742V374.423a52.832 52.832 0 00-52.741-52.741H355.29z" _fill="#0071BC"/><path pid="1" d="M1188.3 961.999h-178.67a40.71 40.71 0 01-39.353-30.487L908.941 696.3h-133.98a40.71 40.71 0 01-39.352-51.204l83.68-313.645a40.71 40.71 0 0139.353-30.216 40.71 40.71 0 0139.262 30.487l80.153 307.584a40.438 40.438 0 013.257 12.394l59.707 229.15h92.728L930.291 196.115a73.006 73.006 0 00-140.04 0L679.067 566.12C673.006 590.727 629.04 766.593 600 827.748c-38.9 81.51-135.246 134.25-245.162 134.25A292.838 292.838 0 0162.271 669.433V355.154A292.386 292.386 0 01605.067 204.8a40.728 40.728 0 11-69.75 42.066 210.966 210.966 0 00-392.079 108.56v314.368a211.328 211.328 0 00211.6 210.786c78.977 0 146.464-34.558 171.885-88.024 21.983-46.228 60.793-194.049 73.82-247.152l.543-1.99 111.454-370.91a154.425 154.425 0 01296.095 0l218.927 737.025a40.71 40.71 0 01-39.262 52.47zM827.974 614.79h59.708L858.19 501.528z" _fill="#0071BC"/><path pid="2" d="M704.127 961.999a40.71 40.71 0 01-39.353-51.204l27.14-100.417a40.71 40.71 0 1178.705 20.898l-27.14 100.417a40.71 40.71 0 01-39.352 30.306z" _fill="#0071BC"/>'
  }
})
