import Vue from "vue";
import Layout from "@/components/Layouts";
import VueRouter from "vue-router";
import RouteView from "@/components/Layouts/RouteView.vue";
import HomeView from "../views/HomeView.vue";

import SystemRoute from "./modules/system";
import OaRoute from "./modules/oa";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "Base",
    component: Layout,
    redirect: "/dashboard",
    meta: { title: "基座", icon: "home" },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: { title: "基座首页", icon: "home" },
        component: HomeView,
      },
      {
        path: "/about",
        name: "About",
        meta: { title: "基座关于", icon: "nested" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
      {
        path: "/income-tax",
        name: "IncomeTax",
        meta: { title: "个税计算", icon: "attendance-overtime-approval" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/income-tax/index.vue"
          ),
      },
    ],
  },
  {
    path: "/user",
    name: "User",
    component: RouteView,
    redirect: "/login",
    isNotLeft: true,
    children: [
      {
        path: "/login",
        name: "Login",
        meta: { title: "系统登录", icon: "home" },
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/login.vue"),
      },
    ],
  },
  SystemRoute,
  OaRoute,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 处理重复点击同一个路由报错的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
