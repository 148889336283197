/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attendance-overtime-approval': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M499.712 225.248v68.288h57.312V422.4h-51.808v51.424a338.88 338.88 0 01124.448-83.328v-96.992h60.032v-68.256H499.712zM399.488 522.656a6642.93 6642.93 0 004.96-223.616h-58.944A2930.304 2930.304 0 01340 517.152c19.456.736 39.296 2.56 59.488 5.504zm-55.616 69.92c-19.648 3.488-38.912 6.752-57.824 9.696V475.84h48.448v-67.2h-48.448V295.712h52.832v-68.288H161.024v68.288h56.736v112.896h-53.984v67.2h53.984v136.32c-22.016 3.136-43.52 5.824-64.448 8l8.256 72.672 177.344-29.184c.736-20.928 2.368-44.608 4.96-71.04zm80.384-133.824c0 99.488-31.008 175.68-93.056 228.576a826.438 826.438 0 0158.368 48.992c8.096-7.872 15.68-16.128 22.816-24.64 0-1.696-.256-3.328-.256-4.992 0-81.952 29.056-157.056 77.408-215.68.256-6.72.768-13.248.768-20.16V199.904h-66.08v258.848z" _fill="#CFCFCF"/><path pid="1" d="M432.224 797.824H88.416a10.432 10.432 0 01-10.464-10.496V116.672c0-5.76 4.672-10.496 10.464-10.496h670.656a10.482 10.482 0 0110.496 10.496v265.472a311.456 311.456 0 0173.376 18.688v-284.16a83.84 83.84 0 00-83.872-83.84H88.416a83.84 83.84 0 00-83.84 83.84v670.656a83.84 83.84 0 0083.84 83.872h380.96a315.712 315.712 0 01-37.152-73.376zM659.68 695.424l39.904 77.376s64.864-157.184 167.104-209.568c-2.432 37.44-12.448 69.888 4.992 109.76-44.864 10.016-137.152 122.24-167.104 177.12-42.432-52.384-92.32-92.256-127.232-104.736l82.336-49.952z" _fill="#CFCFCF"/><path pid="2" d="M745.6 434.24a272.48 272.48 0 100 544.96 272.48 272.48 0 000-544.96zm0 471.584a199.104 199.104 0 110-398.24 199.136 199.136 0 010 398.24z" _fill="#CFCFCF"/>'
  }
})
