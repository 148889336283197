import config from "./config";

export const init = () => {
  config.regist("theme", {
    light: {
        layoutLeftBg: "#ffffff",
        layoutLeftText: "#303133",
        layoutLeftActiveText: "#409eff",
    },
    dark: {
      layoutLeftBg: "#2c3e50",
      layoutLeftText: "#ecf0f1",
      layoutLeftActiveText: "#1890ff",
    },
  });
};
