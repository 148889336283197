<!-- 头部栏 -->
<template>
  <div
    class="layout-header"
    :style="{
      backgroundColor:
        sidebar.position === 'header' ? theme.layoutLeftBg : null,
    }"
  >
    <div class="dfa">
      <div @click="toggle" class="fold-btn" v-if="sidebar.position === 'left'">
        <icon
          :name="sidebar.opened ? 'icon-unfold' : 'icon-fold'"
          scale="1"
        ></icon>
      </div>
      <breadcrumb v-if="breadcrumbPosition === 'header'" />
      <layout-aside mode="horizontal" v-if="sidebar.position === 'header'">
      </layout-aside>
    </div>

    <div class="right-menu">
      <el-dropdown>
        <div class="avator-name-wrap">
          <img
            :src="userInfo?.avatar"
          />
          <div
            class="name"
            :style="{
              color:
                sidebar.position === 'header' ? theme.layoutLeftText : null,
            }"
          >
            {{ userInfo?.username }}
          </div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { LayoutAside } from "./index";
import config from "@/config/config";
export default {
  name: "LayoutHeader",
  components: { Breadcrumb, LayoutAside },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("app", ["sidebar", "breadcrumbPosition"]),
    ...mapGetters("user", ["userInfo"]),
    theme() {
      console.log(this.sidebar, "theme");
      return config.get("theme")[this.sidebar.themeType];
    },
  },
  methods: {
    ...mapActions("app", ["toggleCollapse"]),
    toggle() {
      this.toggleCollapse(!this.sidebar.opened);
    },

    // 退出登录
    logout() {
      this.$store.dispatch("user/logout");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/common-variables.less");
.layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: @layout-header-height;
  box-shadow: 0 0 4px 0px #999;
  .fold-btn {
    width: 24px;
    height: 24px;
    color: @page-color;
    margin: 0 5px 4px 5px;
    svg {
      height: 24px;
    }
    .is-active {
      transform: rotate(180deg);
    }
  }
  .right-menu {
    .avator-name-wrap {
      display: flex;
      align-items: center;
      margin-right: 20px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .name {
        line-height: @layout-header-height;
      }
    }
  }
}
.dfa {
  display: flex;
  align-items: center;
}
</style>
