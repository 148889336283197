/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M128 256h768v85.333H128V256m0 213.333h768v85.334H128v-85.334m0 213.334h768V768H128v-85.333z"/>'
  }
})
