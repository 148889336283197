import axios from "axios";
import { getCache, clearCache } from "./auth";

const baseURL = "https://api.xiaoxi.work"

const request = axios.create({
  baseURL: baseURL + "/api",
  headers: {
    post: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  },
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    const token = getCache();
    if (token) {
      // 让每个请求携带token
      config.data = { ...config.data };
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.data = stringify(config.data, { arrayFormat: "brackets" });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    const ret = response.data;
    if (ret.status) {
      switch (ret.status) {
        case 200:
          return ret;
        case 401:
          MessageBox.confirm("您的登录已过期，请重新登录", "提示", {
            confirmButtonText: "去登录",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            clearCache();
            location.href = "/login";
          });
          return Promise.reject(ret);
        default:
          return Promise.reject(ret);
      }
    } else {
      return Promise.reject("status");
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;
