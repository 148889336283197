/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hr-approval': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M691.2 599.04v238.933h-68.267v-97.28h-76.8v97.28h-68.266V599.04h68.266v83.627h75.094V599.04H691.2zm209.92 238.933l-49.493-80.213h-13.654v80.213h-68.266V599.04h97.28c30.72 0 52.906 6.827 69.973 18.773 15.36 11.947 23.893 32.427 23.893 61.44 0 18.774-3.413 34.134-10.24 46.08-6.826 11.947-17.066 20.48-32.426 25.6l64.853 87.04h-81.92zm-63.147-129.706h17.067c13.653 0 23.893-1.707 29.013-6.827 5.12-5.12 8.534-11.947 8.534-23.893 0-5.12 0-10.24-1.707-13.654-1.707-3.413-3.413-6.826-6.827-8.533-3.413-1.707-6.826-3.413-11.946-5.12-5.12 0-10.24-1.707-17.067-1.707h-17.067v59.734zM512 520.533c-133.12 0-240.64-107.52-240.64-240.64S378.88 39.253 512 39.253s240.64 107.52 240.64 240.64S645.12 520.533 512 520.533zm0-430.08c-104.107 0-189.44 85.334-189.44 189.44s85.333 189.44 189.44 189.44S701.44 384 701.44 279.893 616.107 90.453 512 90.453z" _fill="#333"/><path pid="1" d="M518.827 972.8H59.733c-13.653 0-25.6-11.947-25.6-25.6 0-262.827 215.04-477.867 477.867-477.867 13.653 0 25.6 11.947 25.6 25.6s-11.947 25.6-25.6 25.6c-226.987 0-413.013 177.494-426.667 401.067h433.494c13.653 0 25.6 11.947 25.6 25.6s-11.947 25.6-25.6 25.6z" _fill="#333"/>'
  }
})
