/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logout': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M725.333 736V597.333H426.667V426.667h298.666V288l224 224-224 224M554.667 85.333A85.333 85.333 0 01640 170.667v170.666h-85.333V170.667h-384v682.666h384V682.667H640v170.666a85.333 85.333 0 01-85.333 85.334h-384a85.333 85.333 0 01-85.334-85.334V170.667a85.333 85.333 0 0185.334-85.334h384z"/>'
  }
})
