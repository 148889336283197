// 子应用菜单

import Layout from "@/components/Layouts";
const appRouter = {
  path: "/oa",
  component: Layout,
  redirect: "/oa/dashboard",
  name: "Oa",
  meta: {
    title: "强盛首页",
    icon: "oa-logo",
  },
  children: [
    {
      path: "/oa/dashboard",
      name: "OADashboard",
      meta: { title: "仪表盘", icon: "dashboard" },
    },
    {
      path: "/oa/apply/induction",
      name: "OASystemUser",
      meta: { title: "更新信息", icon: "system-user" },
    },
  ],
};
export default appRouter;