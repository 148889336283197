/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apply': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M864.625 856.674a35.277 35.277 0 01-14.7-59.834 172.864 172.864 0 0047.511-120.558c0-72.008-45.417-115.955-101.45-115.955s-101.434 43.947-101.434 115.955a173.577 173.577 0 0047.228 120.41h-.178a35.291 35.291 0 01-14.728 60.13c-94.309 18.114-141.611 70.227-141.611 109.275 0 47.955 82.683 57.903 210.724 57.903s210.753-9.948 210.753-57.903c0-39.048-47.57-91.31-142.115-109.423zM610.102 341.927H255.777v-89.23h354.325v89.23zm0 193.754H255.777v-89.38h354.325v89.38zM476.478 727.208h-220.7v-89.23h220.7v89.23zm.832 179.055H106.654V89.379H759.24v385.726h89.26V76.314A76.299 76.299 0 00772.232.148H93.721a76.284 76.284 0 00-76.253 76.166v843.014a76.284 76.284 0 0076.254 76.166h383.647v-89.231z" _fill="#FF7245"/>'
  }
})
