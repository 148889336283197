/* eslint-disable */
require('./apply-changesalary')
require('./apply-induction')
require('./apply-posttransfer')
require('./apply-quit')
require('./apply-regularization')
require('./apply')
require('./approval-changesalary')
require('./approval-induction')
require('./approval-posttransfer')
require('./approval-quit')
require('./approval-regularization')
require('./article')
require('./attendance-leave-apply')
require('./attendance-leave-approval')
require('./attendance-overtime-apply')
require('./attendance-overtime-approval')
require('./attendance-replacement-apply')
require('./attendance-replacement-approval')
require('./attendance-staff-attendance')
require('./attendance')
require('./base_info')
require('./base')
require('./check')
require('./dashboard')
require('./dictionary')
require('./fullscreen-exit')
require('./fullscreen')
require('./home')
require('./hr-approval')
require('./icon-fold')
require('./icon-unfold')
require('./logout')
require('./menu')
require('./nested')
require('./oa-logo')
require('./system-department')
require('./system-menu')
require('./system-permission')
require('./system-position')
require('./system-role')
require('./system-user')
require('./system')
