/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-fold': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M844.8 234.667H179.2c-12.8 0-25.6-12.8-25.6-25.6s12.8-25.6 25.6-25.6h665.6c12.8 0 25.6 12.8 25.6 25.6s-12.8 25.6-25.6 25.6zm0 198.4h-448c-12.8 0-25.6-12.8-25.6-32 0-12.8 12.8-25.6 25.6-25.6h448c12.8 0 25.6 12.8 25.6 25.6 0 19.2-12.8 32-25.6 32zm0 192h-448c-12.8 0-25.6-12.8-25.6-25.6s12.8-25.6 25.6-25.6h448c12.8 0 25.6 12.8 25.6 25.6s-12.8 25.6-25.6 25.6zm0 198.4H179.2c-12.8 0-25.6-12.8-25.6-25.6s12.8-25.6 25.6-25.6h665.6c12.8 0 25.6 12.8 25.6 25.6s-12.8 25.6-25.6 25.6zm-518.4-313.6l-140.8 83.2c-12.8 6.4-32 0-32-19.2v-160c0-19.2 19.2-25.6 32-19.2l140.8 83.2c12.8 6.4 12.8 25.6 0 32z"/>'
  }
})
