/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attendance-overtime-apply': {
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M798 629c0-7.2-5.8-13-13-13H499l-52 39h338c7.2 0 13-5.8 13-13v-13zM785 720H278c-7.2 0-13 5.8-13 13v13c0 7.2 5.8 13 13 13h507c7.2 0 13-5.8 13-13v-13c0-7.2-5.8-13-13-13zM798 538v-13c0-7.2-5.8-13-13-13H603l-13 39h195c7.2 0 13-5.8 13-13zM446.9 273.6l-9.3-9.1c-5.2-5-14.3-4-20.4 2.3L355 330.6c-1.8-.3-3.6-.6-5.5-.6-25.1 0-45.5 20.4-45.5 45.5 0 18.6 11.1 34.5 27.1 41.5-.7 1.9-1.1 3.9-1.1 6.1v60.7c0 8.4 5.8 15.2 13 15.2h13c7.2 0 13-6.8 13-15.2v-60.7c0-2.2-.4-4.2-1.1-6.1 16-7.1 27.1-23 27.1-41.5 0-8.6-2.5-16.5-6.6-23.3l56.7-58.1c6.2-6.3 7-15.5 1.8-20.5z"/><path pid="1" d="M349.5 603C475.2 603 577 501.2 577 375.5 577 249.9 475.2 148 349.5 148c-29.2 0-57 5.7-82.7 15.7l22.3 33.4c19-6.5 39.3-10.1 60.4-10.1C453.6 187 538 271.4 538 375.5S453.6 564 349.5 564 161 479.6 161 375.5c0-33.9 9.4-65.3 25-92.8l40 34.3s12 6.1 13-13v-91c-.4-9.6-2.2-12.7-13-13-1-.6-91 13-91 13s-21.5 7 0 26l20.7 17.7c-21.3 34.6-33.7 75.2-33.7 118.8C122 501.2 223.8 603 349.5 603z"/><path pid="2" d="M863 265h-78v-13c0-7.2-5.8-13-13-13h-52c-7.2 0-13 5.8-13 13v13H603c4.3 10.3 13 39 13 39h91v13c0 7.2 5.8 13 13 13h52c7.2 0 13-5.8 13-13v-13h78v104H616v39h247v390H213V616l-39-26v247c0 21.5 17.5 39 39 39h650c21.5 0 39-17.5 39-39V304c0-21.6-17.5-39-39-39z"/>'
  }
})
