import request from "@/utils/request";

// 获取oa系统的token
export const login = (params) => {
  return request({
    url: "/user/login",
    method: "post",
    data: params,
  });
};
