// 子应用到菜单
import Layout from "@/components/Layouts";
const appRouter = {
  path: "/system",
  component: Layout,
  redirect: "/system/dashboard",
  name: "System",
  meta: {
    title: "微应用",
    icon: "base",
  },
  children: [
    {
      path: "/system/dashboard",
      name: "SystemHome",
      meta: { title: "微应用首页", icon: "base" },
    },
    {
      path: "/system/about",
      name: "SystemAbout",
      meta: { title: "微应用关于", icon: "menu" },
    },
  ],
};
export default appRouter;
